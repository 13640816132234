<template>
  <div class="bg-color px-3">
    <v-container class="v-container-fluid" fluid>
      <!-- Primer renglón -->
      <v-row class="align-center">
        <!-- Primera columna: Logo -->
        <v-col cols="12" md="4" sm="12" xs="12">
          <div class="content-image-logo">
            <div class="content-logo" style="margin-left: -7px; margin-bottom: 25px; margin-top: 15px;">
              <img src="@/assets/logos/Logotipo_Negativo.png" alt="Logotipo Negativo" />
            </div>
          </div>
        </v-col>

        <!-- Segunda columna: Texto -->
        <v-col cols="12" md="4" sm="12" xs="12" class="d-flex align-center justify-center">
          <p class="txt-description mon-regular text-centered">
            FRESHER, la plataforma donde puedes realizar pagos con diferentes divisas a proveedores, así como llevar un control de las ganancias obtenidas.
          </p>
        </v-col>

        <!-- Tercera columna: Redes -->
        <v-col cols="12" md="4" sm="12" xs="12" class="d-flex align-center justify-center">
          <div class="content-social-icons">
            <a href="https://www.facebook.com/tu-url-facebook" target="_blank" style="text-decoration: none;">
              <img src="" alt="Facebook" style="width: 30px; margin-right: 10px;" />
            </a>
            <a href="https://www.instagram.com/tu-url-instagram" target="_blank" style="text-decoration: none;">
              <img src="" alt="Instagram" style="width: 30px;" />
            </a>
          </div>
        </v-col>
      </v-row>

      <!-- Separador -->
      <v-row>
        <v-col cols="12">
          <hr class="separator-line" />
        </v-col>
      </v-row>

      <!-- Segundo renglón -->
      <v-row class="align-center">
        <!-- Primera columna: Términos y condiciones -->
        <v-col cols="12" md="4" sm="12" xs="12" class="text-center">
          <p class="txt-link" @click="redirectToTerms" style="cursor: pointer;">
            Términos y condiciones
          </p>
        </v-col>

        <!-- Segunda columna: Derechos reservados -->
        <v-col cols="12" md="4" sm="12" xs="12" class="text-center">
          <p class="txt-description mon-regular text-centered">
            Todos los derechos reservados FRESHER
          </p>
        </v-col>

        <!-- Tercera columna: Creado por PROPLAT -->
        <v-col cols="12" md="4" sm="12" xs="12" class="text-center">
          <p class="txt-description mon-regular text-centered">
            Creado por PROPLAT
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      //VARIABLES
      texts: "",
    };
  },
  beforeMount: function () {
    this.texts = FILE.landing[this.selectLanguage];
  },
  methods: {
    scrollToSection(sectionId) {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        const offset = 100; // Píxeles de desplazamiento deseado
        const targetPosition = targetElement.offsetTop - offset;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      }
    },
    redirectLink: function () {
      let link =
        "https://proplat-public-images.s3.amazonaws.com/PROPLAT-TERMINOS_Y_CONDICIONES.pdf";
      window.open(link, "blank");
    },
    redirectFacebook: function () {
      window.open(
        "https://www.facebook.com/people/Proplat-Proplat/100073376927733/",
        "_blank"
      );
    },
    redirectInstagram: function () {
      window.open("https://www.instagram.com/proplat.inc/", "_blank");
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.landing[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.bg-color {
  width: 100%;
  background: #121A39;
  opacity: 1;
  padding-top: 25px;
  padding-bottom: 25px;
}

.content-logo img {
  width: 100%;
  height: auto;
}

.txt-description {
  text-align: center;
  letter-spacing: 0px;
  font-weight: 800;
  color: #BFD7ED;
  opacity: 1;
  font-size: 14px; /* Tamaño más pequeño */
  margin-bottom: 10px;
}

.txt-link {
  text-align: center;
  letter-spacing: 0px;
  color: #BFD7ED;
  opacity: 1;
  font-size: 14px; /* Tamaño más pequeño */
  margin-bottom: 0;
  cursor: pointer;
  text-decoration: underline;
}

.separator-line {
  border: 1px solid #bebebe;
}

.content-social-icons a img {
  width: 30px;
}

@media (max-width: 600px) {
  .content-logo,
  .txt-description,
  .content-social-icons {
    text-align: center !important;
    justify-content: center !important;
  }
}
</style>